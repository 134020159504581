import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/Layout"
// import PageParticles from "../components/PageParticles"
export default ({ data, pageContext }: any) => {
  data.wpProject.blocks = data.wpProject.blocks.map(
    ({
      name,
      attributesJSON,
      innerBlocks,
    }: {
      name: string
      attributesJSON: any
      innerBlocks: any
    }) => {
      if (name === "genesis-custom-blocks/projects") {
        return {
          name,
          attributesJSON: JSON.stringify({
            ...JSON.parse(attributesJSON),
            pageContext,
          }),
          innerBlocks,
        }
        JSON.parse
      } else {
        return {
          name,
          attributesJSON,
          innerBlocks,
        }
      }
    }
  )

  return (
    <>
      <Layout pageData={data.wpProject} />
      {/* <PageParticles /> */}
    </>
  )
}

export const pageQuery = graphql`
  query ($id: String) {
    wpProject(id: { eq: $id }) {
      id
      title
      content
      status
      language {
        code
        locale
      }
      translations {
        language {
          code
          homeUrl
          id
          locale
          name
          slug
        }
        link
      }
      seo {
        metaRobotsNofollow
        metaKeywords
        metaDesc
        schema {
          raw
        }
        opengraphModifiedTime
        opengraphType
        opengraphSiteName
        opengraphTitle
        opengraphPublisher
        opengraphImage {
          width
          height
          guid
          mimeType
        }
        title
        opengraphUrl
      }
      blocks {
        attributesJSON
        name
        innerBlocks {
          attributesJSON
          innerBlocks {
            attributesJSON
          }
        }
      }
    }
  }
`
